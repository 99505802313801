import React, { useState, useEffect, useRef } from 'react';
import logoSrc from "../../../images/iDriveSoCal-header-logo-300x71.png";
import * as navData from '../../../../data/navigation.json'
import * as styles from "./Navigation.css";

const Navigation = ({ offset }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navbarRef = useRef();

  const handleScroll = () => {
    setIsSticky(navbarRef.current.getBoundingClientRect().top <= offset);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [offset]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <nav ref={navbarRef} className={`bg-white ${isSticky ? 'sticky top-0 z-100' : ''} md:h-[110px] md:max-h-[110px]  px-6 md:px-10`}>
      <div className='flex items-center justify-between w-11/12 max-w-7xl m-auto'>
      <Logo src={logoSrc.src} alt="iDriveSoCal" />
      <MenuButton isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
      <Menu
        menuItems={navData.items}
        isMenuOpen={isMenuOpen}
      />
      </div>
    </nav>
  );
};

export default Navigation;

const Logo = ({ src, alt }) => (
  <header className="basis-2/5 md:basis-3/12 lg:basis-1/6 my-7 mx-0 w-full h-full z-100">
    <a href="/" className="flex w-full h-full items-center">
      <img src={src} alt={alt} className="w-full h-auto object-cover"></img>
    </a>
  </header>
);
const HamburgerIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
    />
  </svg>
);

const CloseIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M6 18L18 6M6 6l12 12"
    />
  </svg>
);

const MenuIcon = ({ isOpen }) => (
  isOpen ? <CloseIcon /> : <HamburgerIcon />
);

const MenuButton = ({ isMenuOpen, toggleMenu }) => (
    <button
      id="menuButton"
      title="Open Main Menu"
      onClick={toggleMenu}
      className="block cursor-pointer md:hidden z-50"
    >
      <MenuIcon isOpen={isMenuOpen} />
    </button>
);


const Menu = ({ menuItems, isMenuOpen }) => (
  <div
    id="header-nav_container"
    className={`${
      isMenuOpen ? "flex" : "hidden"
    } md:flex flex-col md:flex-row items-center bg-white absolute md:static left-0 w-full md:w-auto z-40 text-2xl md:text-lg transition-all duration-300`}
    style={{ top: '110px' }} // Only needed for mobile view
  >
    {menuItems.map((item, index) => (
      <a key={index} href={item.path} className={`link link-primary uppercase px-4 py-2 text-gray-500 ${styles.customUnderline}`}>
        {item.text}
      </a>
    ))}
  </div>
);
